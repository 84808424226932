import {
  CATALOG_STATUS_ACTIVE,
  CATALOG_STATUS_DRAFT,
} from '../../../utils/statuses/catalogStatus';
import {
  CATALOG_TYPE_BOX,
  CATALOG_TYPE_MASTER,
  CATALOG_TYPE_THEMES,
} from '../../../utils/types/catalogTypes';
import { createReferenceEqualSelector } from '../utils';
import { selectCatalogEntities, selectCatalogs } from '.';

export const boxCatalogSelector = (state) =>
  getCatalogFromType(CATALOG_TYPE_BOX, CATALOG_STATUS_ACTIVE, state);

export const masterCatalogIdSelector = (state) =>
  getCatalogIdFromType(CATALOG_TYPE_MASTER, CATALOG_STATUS_ACTIVE, state);

export const masterDraftCatalogIdSelector = (state) =>
  getCatalogIdFromType(CATALOG_TYPE_MASTER, CATALOG_STATUS_DRAFT, state);
export const themesCatalogIdSelector = (state) =>
  getCatalogIdFromType(CATALOG_TYPE_THEMES, CATALOG_STATUS_ACTIVE, state);

function getCatalogIdFromType(type, status, state) {
  const entities = selectCatalogEntities(state);
  return Object.values(entities).find(
    (c) => c.catalogType === type && c.catalogStatus === status,
  )?.catalogId;
}

function getCatalogFromType(type, status, state) {
  const entities = selectCatalogEntities(state);
  return Object.values(entities).find(
    (c) => c.catalogType === type && c.catalogStatus === status,
  );
}

export const selectSearchableCatalogIds = createReferenceEqualSelector(
  selectCatalogEntities,
  (entities) =>
    Object.entries(entities).reduce((ids, [id]) => {
      if (!Number.isNaN(parseInt(id, 10))) {
        ids.push(id);
      }
      return ids;
    }, []),
);

export const selectSearchableCatalogs = createReferenceEqualSelector(
  selectCatalogs,
  (catalogs) =>
    catalogs.filter((catalog) => !Number.isNaN(parseInt(catalog.catalogId, 10))),
);
