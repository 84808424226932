import { STATE_NEW, STATE_UNPUBLISHED } from '../../../utils/category/states';
import {
  getMissing,
  getUnpublished,
  getUntranslated,
} from '../../../utils/tree/indicators';
import { getNodeChildItemCount, getNodeType } from '../../../utils/tree/nodeItem';
import { CATALOG_TYPE_BOX } from '../../../utils/types/catalogTypes';
import {
  NODE_TYPE_CATALOG,
  NODE_TYPE_CATEGORY,
} from '../../../utils/types/nodeTypes';
import { selectCategoriesStatusByCatalogId } from '../../slices/indicator';
import { selectIsGlobalMarket } from '../../slices/intl';
import { selectIsEditorForCurrentMarket } from '../roles';
import {
  selectSelectedNodeItem,
  selectTreeDataByCatalogId,
  selectTreeDataByTreeId,
} from '../tree';
import { treeConfigSelector } from '../tree/data';
import {
  createReferenceEqualSelector,
  createShallowEqualResultSelector,
  getCatalogIdFromTreeId,
} from '../utils';

export const newCategoryEnabledSelector = createReferenceEqualSelector(
  selectSelectedNodeItem,
  getCatalogIdFromTreeId,
  selectTreeDataByTreeId,
  selectIsGlobalMarket,
  (selectedNodeItem, catalogId, treeData, isGlobal) => {
    const nodeItem = selectedNodeItem?.[catalogId];
    const nodeType = getNodeType(nodeItem);
    if (
      nodeItem?.node.isNew ||
      ![NODE_TYPE_CATALOG, NODE_TYPE_CATEGORY].includes(nodeType) ||
      getNodeChildItemCount(nodeItem)
    ) {
      return false;
    }

    if (
      treeData[0].catalog.catalogType === CATALOG_TYPE_BOX &&
      (nodeItem.node.level > 0 || !isGlobal)
    ) {
      // only allow creation of new category on root level for box catalog
      return false;
    }
    return true;
  },
);

export const indicatorsSelector = createShallowEqualResultSelector(
  selectCategoriesStatusByCatalogId,
  selectTreeDataByCatalogId,
  (state, catalogId, category) => category,
  (categoryStatus, treeData, category) => {
    const publishState =
      category?.newCategory && category.publishState === STATE_UNPUBLISHED
        ? null
        : category?.publishState;
    return {
      badges: [
        category?.translateState || '',
        category?.newCategory ? STATE_NEW : '',
        publishState || '',
      ],
      summaries: {
        deleted: category?.isMissing ? getMissing(treeData, category) : 0,
        unpublished: getUnpublished(categoryStatus, category),
        untranslated: getUntranslated(categoryStatus, category),
      },
    };
  },
);

export const selectIsGlobalCategoryNameEditable = createReferenceEqualSelector(
  treeConfigSelector,
  selectIsGlobalMarket,
  selectIsEditorForCurrentMarket,
  selectSelectedNodeItem,
  (treeConfig, isGlobalMarket, isEditor, nodeItem) =>
    !!(
      treeConfig?.categoryNameEditable &&
      isGlobalMarket &&
      isEditor &&
      !nodeItem?.node?.category?.isMissing
    ),
);
