import { z } from 'zod';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isCatalogDraft } from '../../../utils/catalog';
import { comparer } from '../../../utils/catalog/comparer';
import { MISSING_ITEMS_CATALOG } from '../../../utils/catalog/missingitems';
import { DELETE, GET, POST, PUT } from '.';
import { NOT_VALID_FOR_STRUCURE_CATALOG } from '../../../utils/catalog/notvalidforstructure';
import { UNCATEGORIZED_CATALOG } from '../../../utils/catalog/uncategorized';
import { toDateString } from '../../../utils/date';
import CreateDraftCatalogError from '../../../utils/error/CreateDraftCatalogError';
import { isGlobalMarket } from '../../../utils/market';
import { extendFromEntries } from '../../../utils/object';
import {
  CATALOG_TYPE_MISSINGITEMS,
  CATALOG_TYPE_NOTVALIDFORSTRUCTURE,
  CATALOG_TYPE_UNCATEGORIZED,
} from '../../../utils/types/catalogTypes';
import {
  catalogFromDto,
  catalogStatusFromDto,
  catalogToDto,
} from '../../mappers/dtoMappers';
import { dataFromDto } from '../../mappers/treeDto';
import { selectMarketProps } from '../../selectors/market';
import {
  CatalogsSchema,
  CatalogSchema,
  CatalogCategoriesSchema,
  DraftCatalogSchema,
  ToBeDeletedSchema,
} from '../../../schemas/catalogs';
import { Catalog } from '../../../entities/Catalog';
import RequestFailedError from '../../../utils/error/RequestFailedError';
import type { RootState } from '../..';
import { selectIsEditorForCurrentMarket } from '../../selectors/roles';

export const getAll = createAsyncThunk<
  { catalogs: Catalog[] },
  void,
  { state: RootState }
>('catalog/getAll', async (_, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const state = getState();
  const { languageCode, retailUnit } = selectMarketProps(state) as Record<
    string,
    string
  >;

  if (!languageCode || !retailUnit) return { catalogs: [] };

  const globalMarket = isGlobalMarket(retailUnit);
  const showUncategorizedItems =
    globalMarket && selectIsEditorForCurrentMarket(state);

  const response = await dispatch(
    GET({
      path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues`,
      label: `Fetch all Catalogues`,
    }),
  );

  const parsedCatalogs = CatalogsSchema.parse(response);

  const catalogs = parsedCatalogs.map(catalogFromDto);
  if (showUncategorizedItems) {
    catalogs.push(UNCATEGORIZED_CATALOG);
  }
  if (globalMarket) {
    catalogs.push(NOT_VALID_FOR_STRUCURE_CATALOG);
    if (catalogs.some(isCatalogDraft)) {
      catalogs.push(MISSING_ITEMS_CATALOG);
    }
  }
  catalogs.sort(comparer);

  return { catalogs };
});

export const getCatalog = createAsyncThunk<Catalog, string, { state: RootState }>(
  'catalog/getCatalog',
  async (catalogId: string, thunkAPI) => {
    switch (catalogId) {
      case CATALOG_TYPE_MISSINGITEMS:
        return MISSING_ITEMS_CATALOG;
      case CATALOG_TYPE_NOTVALIDFORSTRUCTURE:
        return NOT_VALID_FOR_STRUCURE_CATALOG;
      case CATALOG_TYPE_UNCATEGORIZED:
        return UNCATEGORIZED_CATALOG;
      default: {
        const { dispatch, getState } = thunkAPI;
        const state = getState();
        const { languageCode, retailUnit } = selectMarketProps(state);
        const catalogDto = await dispatch(
          GET({
            path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}`,
            label: `Fetch catalog with id ${catalogId}`,
          }),
        );
        const parsedCatalog = CatalogSchema.parse(catalogDto);

        return catalogFromDto(parsedCatalog);
      }
    }
  },
);

export const getTree = createAsyncThunk(
  'catalog/getTree',
  async (catalogId: string, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    const treeDto = await dispatch(
      GET({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories`,
        label: `Get tree with catalogId: ${catalogId}`,
      }),
    );

    const parsedTree = CatalogCategoriesSchema.parse(treeDto);

    const { catalog, categories } = dataFromDto(parsedTree, languageCode);

    return {
      catalog,
      categories,
      languageCode,
    };
  },
);

export const updateCatalog = createAsyncThunk<
  unknown,
  Catalog,
  { state: RootState }
>('catalog/updateCatalog', async (data: Catalog, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const validProps = [
    'activationDate',
    'catalogueIdentifierTranslations',
    'availableForMarkets',
  ];
  const catalogDto = Object.entries(catalogToDto(data))
    .filter(([prop]) => validProps.includes(prop))
    .reduce(extendFromEntries, {});
  const { languageCode, retailUnit } = selectMarketProps(getState());
  await dispatch(
    PUT({
      path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${data.catalogId}`,
      data: catalogDto,
      label: `Update catalog activation date with id: ${data.catalogId}`,
    }),
  );
  return data;
});

export const toBeDeletedStatus = createAsyncThunk(
  'catalog/toBeDeletedStatus',
  async ({ catalogId }: { catalogId: string }, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const toBeDeletedDto = await dispatch(
      GET({
        path: `/rrm-rc/catalogues/${catalogId}/to-be-deleted-series-collections`,
        label: `Fetch to be deleted info for catalogue: ${catalogId}`,
      }),
    );

    return ToBeDeletedSchema.parse(toBeDeletedDto);
  },
);

export const activateDraft = createAsyncThunk<unknown, string, { state: RootState }>(
  'catalog/activateDraft',
  async (catalogId, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState());
    return dispatch(
      PUT({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/activate`,
        label: `Activate Draft Catalogue with id:${catalogId}`,
      }),
    );
  },
);

export const createDraft = createAsyncThunk<
  unknown,
  { catalogId: string; activationDate: string },
  { state: RootState }
>(
  'catalog/createDraft',
  async (
    { catalogId, activationDate }: { catalogId: string; activationDate: string },
    thunkAPI,
  ) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState());
    return dispatch(
      POST({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/copy-as-draft`,
        data: { activationDate: toDateString(activationDate) },
        onFailure: (error: RequestFailedError) => {
          if (error?.status === 'METHOD_NOT_ALLOWED' || error?.httpCode === 405) {
            return Promise.reject(new CreateDraftCatalogError(error));
          }
          return Promise.reject(error);
        },
        label: `Create Draft Catalogue from id:${catalogId}`,
      }),
    );
  },
);

export const getDraftStatus = createAsyncThunk(
  'catalog/getDraftStatus',
  async (_, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
    const dto = await dispatch(
      GET({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/draft-status`,
        label: 'Get Draft Catalogue Status',
      }),
    );

    const parsedData = DraftCatalogSchema.parse(dto);
    return catalogStatusFromDto(parsedData);
  },
);

export const deleteCatalog = createAsyncThunk<unknown, string, { state: RootState }>(
  'catalog/deleteCatalog',
  async (catalogId, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState());
    return dispatch(
      DELETE({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}`,
        label: `Delete Catalogue with id:${catalogId}`,
      }),
    );
  },
);

export const categorizeItemsSeriesAndCollections = createAsyncThunk(
  'catalog/categorizeSeriesCollections',
  async (catalogId, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const res = await dispatch(
      POST({
        path: `/rrm-rc/ZZ/en/${catalogId}/categorise-items-in-series-and-collections`,
        label: `Categorise items in series and collections`,
      }),
    );

    return res;
  },
);

export const sortABCatalogFromGa = createAsyncThunk<
  unknown,
  {
    catalogId: string;
    start: string | null | undefined;
    end: string | null | undefined;
    variant: string | null;
  },
  { state: RootState }
>(
  'catalog/sortABCatalogFromAnalytics',
  async ({ catalogId, start, end, variant }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { languageCode, retailUnit } = selectMarketProps(getState());

    const res = dispatch(
      POST({
        path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/apply-sorting-from-ga-ab-test-variant?variant=${variant}`,
        data: {
          startDate: toDateString(start),
          endDate: toDateString(end),
        },
        label: `Apply sorting on AB catalog:${catalogId} from GA variant:${variant}`,
        ignoreError: true,
      }),
    );

    return res;
  },
);

export const getInvalidImages = createAsyncThunk<
  string[],
  { catalogId: string },
  { state: RootState }
>('catalog/missingProductImage', async ({ catalogId }, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
  const result = await dispatch(
    GET({
      path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues/${catalogId}/categories/image/missing`,
      label: 'Get missing category product image',
    }),
  );

  return z.array(z.number().transform((val) => `${val}`)).parse(result);
});

export const createNewCatalog = createAsyncThunk<
  ReturnType<typeof catalogFromDto>,
  { catalogueName: string; catalogueType: string },
  { state: RootState }
>('catalog/createCatalog', async ({ catalogueName, catalogueType }, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { languageCode, retailUnit } = selectMarketProps(getState() as RootState);
  const result = await dispatch(
    POST({
      path: `/rrm-rc/${retailUnit}/${languageCode}/catalogues`,
      label: 'Create catalog',
      data: {
        catalogueName,
        catalogueType,
      },
    }),
  );

  const parsedCatalog = CatalogSchema.parse(result);

  return catalogFromDto(parsedCatalog);
});
