import Button from '@ingka/button';
import Tooltip from '@ingka/tooltip';
import { useNavigate, useParams } from 'react-router-dom';
import filters from '@ingka/ssr-icon/paths/filters';
import { useSelector } from 'react-redux';
import { masterCatalogIdSelector } from '../../../store/selectors/catalog/ids';

export const FilterLink = () => {
  const { languageCode, retailUnit } = useParams();
  const catalogId = useSelector(masterCatalogIdSelector);
  const navigate = useNavigate();
  return (
    <Tooltip position="bottom" tooltipText="Indexable filters">
      <Button
        id="indexable-filters-button"
        aria-label="Go to indexable filters"
        size="small"
        name="Go to indexable filters"
        type="tertiary"
        iconOnly
        role="button"
        ssrIcon={filters}
        onClick={() => {
          navigate(
            `${retailUnit}/${languageCode}/filters/${catalogId}?t=categories`,
          );
        }}
      ></Button>
    </Tooltip>
  );
};
