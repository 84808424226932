import ErrorBoundary from './components/ErrorBoundary';
import { useAnalyticsInitialize } from './hooks/useAnalyticsInitialize';
import Auth0Wrapper from './components/Auth0Wrapper';
import MiddlewareWrapper from './components/MiddlewareWrapper/MiddlewareWrapper';
import { Router } from './Router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const App = function App() {
  const client = new QueryClient();

  useAnalyticsInitialize();

  return (
    <ErrorBoundary>
      <MiddlewareWrapper>
        <Auth0Wrapper>
          <QueryClientProvider client={client}>
            <Router />
          </QueryClientProvider>
        </Auth0Wrapper>
      </MiddlewareWrapper>
    </ErrorBoundary>
  );
};

export default App;
