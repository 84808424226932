import { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import Button from '@ingka/button';
import Tooltip from '@ingka/tooltip';
import { useDispatch } from 'react-redux';
import { CircleHalf } from '../../Icons/paths';
import styles from './ToggleThemeButton.module.scss';
import { analyticsEvent } from '../../../store/actions/analytics/analytics';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { AppThunkDispatch } from '../../../store';

const ToggleThemeButton = function ToggleThemeButton() {
  const [darkThemePersisted, setDarkThemePersisted] = useLocalStorage<boolean>(
    'rc-user-settings-dark-theme',
    false,
  );
  const darkTheme =
    darkThemePersisted === undefined
      ? window.matchMedia('(prefers-color-scheme: dark)').matches
      : darkThemePersisted;

  const dispatch = useDispatch<AppThunkDispatch>();
  const [theme, setTheme] = useState(darkTheme);

  const toggleTheme = useCallback(() => {
    setDarkThemePersisted(!theme);
    dispatch(
      analyticsEvent({
        category: 'Theme',
        action: 'Toggle theme',
        label: `Toggled theme to ${theme ? 'dark' : 'light'}`,
      }),
    );
    setTheme(!theme);
  }, [theme, dispatch, setDarkThemePersisted]);

  useEffect(() => {
    const body = document.querySelector('body');
    body?.classList.remove('dark', 'light');
    body?.classList.add(theme ? 'dark' : 'light');
  }, [theme]);

  return (
    <Tooltip position="leading" tooltipText="Toggle theme">
      <Button
        className={cx(styles.theme)}
        onClick={toggleTheme}
        iconOnly
        size="small"
        aria-label="Toggle theme"
        ssrIcon={CircleHalf}
        type="tertiary"
      />
    </Tooltip>
  );
};

export default ToggleThemeButton;
