export const MARKET_AI_NAME_SUFFIX = '(AB Test)';
export const CATALOG_TYPE_AUTOMATED = 'MARKET_AI';
export const CATALOG_TYPE_COLLECTION = 'COLLECTION';
export const CATALOG_TYPE_ITEMSLIST = 'ITEMSLIST';
export const CATALOG_TYPE_MARKET = 'MARKET';
export const CATALOG_TYPE_MASTER = 'GLOBAL';
export const CATALOG_TYPE_MISSINGITEMS = 'MISSING_ITEMS';
export const CATALOG_TYPE_NOTVALIDFORSTRUCTURE = 'NOT_VALID_FOR_STRUCTURE';
export const CATALOG_TYPE_SERIES = 'SERIES';
export const CATALOG_TYPE_THEMES = 'THEMES';
export const CATALOG_TYPE_UNCATEGORIZED = 'UNCATEGORIZED';
export const CATALOG_TYPE_BOX = 'BOX';
