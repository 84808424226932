import { NodeBase } from '../../../entities/Node';
import { MISSING_ITEMS_CATALOG } from '../../catalog/missingitems';
import { UNCATEGORIZED_CATALOG } from '../../catalog/uncategorized';
import {
  NODE_TYPE_CATALOG,
  NODE_TYPE_CATEGORY,
  NODE_TYPE_ITEM,
} from '../../types/nodeTypes';
import DefaultTreeConfig, { TreeConfigProps } from './DefaultTreeConfig';

export default class MasterTreeConfig extends DefaultTreeConfig {
  #isDraft;

  constructor(props: TreeConfigProps) {
    super(props);

    this.#isDraft = props.catalogId !== props.productsCatalogId;
    this.allowHoverActions = props.isEditorForCurrentMarket;
    this.allowCopyCategory = this.editable;
    this.allowCutCategory = this.editable && this.#isDraft;
    // RC-3183
    this.allowRanking = props.isGlobalMarket || props.isEditorForCurrentMarket;
    this.allowedSources = [props.catalogId, UNCATEGORIZED_CATALOG.catalogId];
    if (this.#isDraft) {
      this.allowedSources.push(props.productsCatalogId);
      this.allowedSources.push(MISSING_ITEMS_CATALOG.catalogId);
    } else if (props.productsDraftCatalogId) {
      this.allowedSources.push(props.productsDraftCatalogId);
    }
    this.categoryNameEditable = this.editable;
    this.moveDisabled = !this.#isDraft;
    this.newCategory = this.editable && this.#isDraft;
    this.canPaste = this.canPaste.bind(this);
    this.allowItemOperations = props.isGlobalMarket;
  }

  canPaste(eventArgs: { nextParent: NodeBase; node: NodeBase }) {
    if (
      this.allowRanking &&
      this.#isDraft &&
      eventArgs.nextParent?.type === NODE_TYPE_CATALOG &&
      eventArgs.node?.type === NODE_TYPE_CATEGORY
    ) {
      return true;
    }

    if (
      this.allowRanking ||
      this.#isDraft ||
      eventArgs.node?.type === NODE_TYPE_ITEM
    ) {
      return super.canPaste(eventArgs);
    }
    return false;
  }
}
