import { z } from 'zod';
import { PaginationSchema } from './general';

export const ImageOverridesSchema = z.array(
  z.object({
    categoryId: z.number(),
    externalCategoryId: z.string(),
    categoryName: z.string(),
    image: z.string().nullable(),
    type: z.string(),
    username: z.string(),
    comment: z.string(),
    eventTimestamp: z.string(),
  }),
);

export const CategoryHistorySchema = z.array(
  z.object({
    eventTimestamp: z.string(),
    categoryTranslation: z.string().optional(),
    languageCode: z.string().optional(),
    userName: z.string(),
    action: z.string(),
    comment: z.string().optional().nullable(),
  }),
);

export const CategoryHistoryPaginatedSchema = PaginationSchema.extend({
  content: CategoryHistorySchema,
});

export const CategorySchema = z.object({
  autoCategorisable: z.boolean().nullable(),
  categoryId: z.number().transform((val) => `${val}`),
  mainParentCategoryId: z
    .number()
    .transform((val) => `${val}`)
    .optional(),
  externalCategoryId: z.string().nullable(),
  globalCategoryName: z.string().nullable(),
  globalCategoryPublished: z.boolean(),
  globalCategoryComment: z.string().nullable(),
  categoryComment: z.string().nullable(),
  globalPublishDate: z.string().nullable(),
  categoryNameTranslations: z.record(z.string(), z.string().nullable()).nullable(),
  categoryNameTranslationsVerified: z
    .record(z.string(), z.string().nullable())
    .nullable(),
  childCategoryCount: z.number(),
  childItemCount: z.number(),
  newCategory: z.boolean(),
  translateState: z.string().nullable(),
  publishState: z.string(),
  image: z.string().optional(),
  imageOverride: z.boolean().optional(),
  imageComment: z.string().optional(),
  productImageOverride: z.boolean().optional(),
  categoryType: z.string().nullable(),
  mainParentDTOs: z.array(
    z.object({
      parentCategoryId: z
        .number()
        .transform((val) => `${val}`)
        .optional(),
      parentCategoryName: z.string().nullable(),
      parentExternalCategoryId: z.string().nullable(),
      mainParent: z.boolean(),
    }),
  ),
  productImage: z.object({
    itemNo: z.string().nullable(),
    image: z.string().nullable(),
    productName: z.string().nullable(),
    productType: z.string().nullable(),
  }),
});

export const CategoryCreateSchema = z.object({
  registeredDate: z.string(),
  updatedDate: z.string(),
  lastAction: z.string().nullable(),
  createdUser: z.string().nullable(),
  updatedUser: z.string().nullable(),
  categoryId: z.number().transform((val) => `${val}`),
  categoryType: z.string().nullable(),
  globalCategoryName: z.string(),
  parentCategoryId: z.number().nullable(),
  externalCategoryId: z.string(),
  globalCategoryPublished: z.boolean(),
  globalPublishDate: z.string(),
  globalCategoryComment: z.string().nullable(),
});

export const AutoUnHiddenCategoriesReportsSchema = z.array(
  z.object({
    categoryId: z.number().transform((val) => `${val}`),
    externalCategoryId: z.string(),
    categoryName: z.string(),
    type: z.string(),
    username: z.string(),
    comment: z.string().nullable(),
    eventTimestamp: z.string(),
  }),
);

export const HFBCatalogCategoriesSchema = z.object({
  catalogueName: z.string(),
  globalCatalogueIdentifier: z.string(),
  hfbList: z.array(z.string()),
  hfbToCategory: z.array(
    z.object({
      hfbNo: z.string(),
      hfbName: z.string(),
      allCategories: z.array(
        z.object({
          leafToTopCategories: z
            .array(
              z.object({
                categoryId: z.number().transform((val) => `${val}`),
                categoryName: z.string(),
              }),
            )
            .nullable()
            .transform((val) => (val ? val : [])),
        }),
      ),
    }),
  ),
});
