export const countryPrefix = `:retailUnit/:languageCode`;

export const ROUTE_AUTOCATEGORIZED = `/${countryPrefix}/reports/AutoCategorized`;
export const ROUTE_UNHIDDEN = `/${countryPrefix}/reports/UnHidden`;
export const ROUTE_IMAGE_OVERRIDES = `/${countryPrefix}/reports/ImageOverrides`;
export const ROUTE_ITEM_GROPUPING = `/${countryPrefix}/reports/ItemGrouping`;
export const ROUTE_NVFS = `/${countryPrefix}/reports/NotValidForStructureItems`;
export const ROUTE_FILTERS = `/${countryPrefix}/filters/:catalogId?/*`;
export const ROUTE_HFB = `/${countryPrefix}/reports/Hfb`;

export const ROUTE_HOME = `/${countryPrefix}?`;

export const REPORT_ROUTES = [
  {
    path: ROUTE_AUTOCATEGORIZED,
  },
  {
    path: ROUTE_IMAGE_OVERRIDES,
  },
  {
    path: ROUTE_ITEM_GROPUPING,
  },
  {
    path: ROUTE_UNHIDDEN,
  },
  {
    path: ROUTE_NVFS,
  },
  {
    path: ROUTE_HFB,
  },
];
