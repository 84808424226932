import { includes } from '../../../utils/array';
import { createDeepEqualResultSelector } from '../utils';
import { selectAvailableMarkets } from '.';
import {
  makeFilterRetailUnitRoleName,
  makeRetailUnitRoleName,
} from '../../../utils/roles/roles';
import { selectRoles } from '../../slices/roles';

export const selectMarketsGroupedPerAccess = createDeepEqualResultSelector(
  selectAvailableMarkets,
  selectRoles,
  (markets, roles) =>
    markets.reduce<{
      rcEditorMarkets: {
        retailUnit: string;
        countryName: string;
        languageCodes: string[];
      }[];
      filterEditorMarkets: {
        retailUnit: string;
        countryName: string;
        languageCodes: string[];
      }[];
      viewerMarkets: {
        retailUnit: string;
        countryName: string;
        languageCodes: string[];
      }[];
    }>(
      (acc, market) => {
        const isRCEditor = includes(roles, [
          makeRetailUnitRoleName(market.retailUnit),
        ]);
        const isFilterEditor = includes(roles, [
          makeFilterRetailUnitRoleName(market.retailUnit),
        ]);
        if (isRCEditor) {
          acc.rcEditorMarkets.push(market);
        }
        if (isFilterEditor) {
          acc.filterEditorMarkets.push(market);
        } else {
          acc.viewerMarkets.push(market);
        }
        return acc;
      },
      {
        rcEditorMarkets: [],
        viewerMarkets: [],
        filterEditorMarkets: [],
      },
    ),
);
