import { analyticsEvent } from '../../../../store/actions/analytics/analytics';
import { ANALYTICS_TREE } from '../../../../store/actions/analytics/analyticsCategories';
import { categorizeItems } from '../../../../store/actions/item/categorizeItems';
import { nodeMenuCopy } from '../../../../store/actions/nodeMenu/copy';
import { nodeMenuCut } from '../../../../store/actions/nodeMenu/cut';
import { moveCategory } from '../../../../store/actions/nodeMenu/moveCategory';
import { pasteCopy } from '../../../../store/actions/nodeMenu/pasteCopy';
import { pasteMove } from '../../../../store/actions/nodeMenu/pasteMove';
import { isCategorize, isPastable } from '../../../../utils/command';
import { hasSameContent } from '../../../../utils/tree/treeNode';
import {
  NODE_TYPE_CATEGORY,
  NODE_TYPE_ITEM,
} from '../../../../utils/types/nodeTypes';
import { CATALOG_TYPE_SERIES } from '../../../../utils/types/catalogTypes';

export function getCategoryActions(
  { allowCopyCategory, allowCutCategory, allowRanking, canPaste },
  {
    directive,
    dispatch,
    isCopy,
    nodeItem,
    nodeItems,
    sourceTree,
    tree,
    catalogType,
  },
) {
  const nodeActions = {};

  if (allowCopyCategory) {
    nodeActions.nodeMenuCopy = () => {
      dispatch(
        analyticsEvent({
          category: ANALYTICS_TREE,
          action: 'Copy item clicked',
          label: `Copy item ${nodeItem.node.id}`,
        }),
      );
      dispatch(nodeMenuCopy(tree, nodeItem));
    };
  }
  if (allowCutCategory) {
    nodeActions.nodeMenuCut = () => {
      dispatch(
        analyticsEvent({
          category: ANALYTICS_TREE,
          action: 'Cut item clicked',
          label: `Cut item ${nodeItem.node.id}`,
        }),
      );
      dispatch(nodeMenuCut(tree, nodeItem));
    };
  }
  const isParentCategoryInSeries =
    catalogType === CATALOG_TYPE_SERIES && nodeItem.path.length <= 2;
  const isRankingAllowed = isParentCategoryInSeries ? false : allowRanking;
  if (isRankingAllowed) {
    nodeActions.nodeMenuMoveUp = (callback) => {
      dispatch(
        analyticsEvent({
          category: ANALYTICS_TREE,
          action: 'Move category clicked',
          label: `Move category ${nodeItem.node.id} up`,
        }),
      );
      dispatch(
        moveCategory({
          tree,
          nodeItem,
          callback,
          direction: '-',
        }),
      );
    };
    nodeActions.nodeMenuMoveDown = (callback) => {
      dispatch(
        analyticsEvent({
          category: ANALYTICS_TREE,
          action: 'Move category clicked',
          label: `Move category ${nodeItem.node.id} down`,
        }),
      );
      dispatch(moveCategory({ tree, nodeItem, callback, direction: '+' }));
    };
  }

  const pastableContent = canPaste({
    nextParent: nodeItem.node,
    node: nodeItems?.[0]?.node,
  });

  const sourceType = nodeItems?.[0]?.node?.type;
  if (pastableContent) {
    const childCategoryCount = nodeItem.node?.category?.childCategoryCount;
    const childItemCount = nodeItem.node?.category?.childItemCount;
    if (isCategorize(directive) && !childCategoryCount) {
      nodeActions.pasteAction = async (callback) => {
        dispatch(
          analyticsEvent({
            category: ANALYTICS_TREE,
            action: 'Paste item ',
            label: `Paste item ${nodeItem.node.id}`,
          }),
        );
        await dispatch(categorizeItems(tree, nodeItem));
        callback();
      };
    }
    if (isPastable(directive)) {
      const isCommandItem =
        sourceTree === tree && hasSameContent(nodeItem, nodeItems);
      if (
        !isCommandItem &&
        ((sourceType === NODE_TYPE_ITEM && !childCategoryCount) ||
          (sourceType === NODE_TYPE_CATEGORY && !childItemCount))
      ) {
        nodeActions.pasteAction = isCopy
          ? async (callback) => {
              await dispatch(pasteCopy(tree, nodeItem));
              callback();
            }
          : async (callback) => {
              await dispatch(pasteMove(tree, nodeItem));
              callback();
            };
      }
    }
  }

  return nodeActions;
}
