import cx from 'classnames';
import { useSelector } from 'react-redux';
import missing from '@ingka/ssr-icon/paths/missing';
import incorrect from '@ingka/ssr-icon/paths/incorrect';
import location from '@ingka/ssr-icon/paths/location-pin-small';
import globe from '@ingka/ssr-icon/paths/globe';
import briefcase from '@ingka/ssr-icon/paths/briefcase';
import {
  CATALOG_TYPE_AUTOMATED,
  CATALOG_TYPE_BOX,
  CATALOG_TYPE_MISSINGITEMS,
  CATALOG_TYPE_NOTVALIDFORSTRUCTURE,
  CATALOG_TYPE_UNCATEGORIZED,
} from '../../../utils/types/catalogTypes';
import styles from './CatalogIcon.module.scss';
import { selectIsRetailMarket } from '../../../store/slices/intl';
import SSRIcon from '@ingka/ssr-icon';

const iconMap: { [key: string]: (prefix?: string) => React.SVGProps<SVGElement>[] } =
  {
    [CATALOG_TYPE_AUTOMATED]: incorrect,
    [CATALOG_TYPE_MISSINGITEMS]: missing,
    [CATALOG_TYPE_NOTVALIDFORSTRUCTURE]: incorrect,
    [CATALOG_TYPE_UNCATEGORIZED]: missing,
    [CATALOG_TYPE_BOX]: briefcase,
  };

const CatalogIcon = function CatalogIcon({
  catalogType,
  className,
}: {
  catalogType: string;
  className: string;
}) {
  const isMarketView = useSelector(selectIsRetailMarket);

  if (iconMap[catalogType]) {
    return (
      <SSRIcon paths={iconMap[catalogType]} className={cx(styles.icon, className)} />
    );
  }

  return (
    <SSRIcon
      paths={isMarketView ? location : globe}
      className={cx(styles.icon, className)}
    />
  );
};

export default CatalogIcon;
