import DefaultTreeConfig, { TreeConfigProps } from './DefaultTreeConfig';

export default class BoxTreeConfig extends DefaultTreeConfig {
  constructor(props: TreeConfigProps) {
    super(props);
    this.editable = props.isEditorForCurrentMarket;

    this.categoryNameEditable = props.isEditorForCurrentMarket;
    this.allowRanking = props.isEditorForCurrentMarket;
    this.newCategory = props.isEditorForCurrentMarket;
    this.allowHoverActions = props.isEditorForCurrentMarket;
    this.allowCutCategory = props.isEditorForCurrentMarket;
    this.allowItemOperations = props.isEditorForCurrentMarket;
    this.allowCopyCategory = props.isEditorForCurrentMarket;
    this.moveDisabled = false;
    this.canPaste = ({ nextParent }) => {
      return nextParent?.level === 1;
    };
  }
}
