import { canPasteNode } from '../../../components/Tree/helpers/canPasteHandlers';
import { NodeBase } from '../../../entities/Node';

export type TreeConfigProps = {
  catalogId: string;
  productsCatalogId: string;
  productsDraftCatalogId?: string;
  isEditorForCurrentMarket: boolean;
  isGlobalEditor: boolean;
  isGlobalMarket: boolean;
};

export default class DefaultTreeConfig {
  allowedSources;

  allowHoverActions = false;

  allowCopyCategory = false;

  allowCutCategory = false;

  allowItemOperations = false;

  allowRanking = false;

  categoryNameEditable = false;

  editable = false;

  indentTree = false;

  moveDisabled = true;

  newCategory = false;

  onlyExpandSearchedNodes = true;

  constructor({
    catalogId,
    productsCatalogId,
    isEditorForCurrentMarket,
    isGlobalMarket,
  }: TreeConfigProps) {
    this.editable = isGlobalMarket && isEditorForCurrentMarket;

    this.indentTree = this.editable;
    this.allowedSources = [catalogId];
    if (catalogId !== productsCatalogId) {
      this.allowedSources.push(productsCatalogId);
    }
    this.canPaste = this.canPaste.bind(this);
  }

  canPaste(eventArgs: { nextParent: NodeBase; node: NodeBase }) {
    if (
      !(this.editable || this.allowRanking) ||
      !this.allowedSources.includes(eventArgs?.node?.catalogId)
    ) {
      return false;
    }
    return canPasteNode(eventArgs, this.moveDisabled);
  }
}
