import { pasteCopy } from '../../../../store/actions/nodeMenu/pasteCopy';
import { pasteMove } from '../../../../store/actions/nodeMenu/pasteMove';
import { isPastable } from '../../../../utils/command';

export function getCatalogActions(
  { canPaste },
  { directive, dispatch, isCopy, targetNodeItem, sourceNodeItems, tree },
) {
  if (
    canPaste({ nextParent: targetNodeItem.node, node: sourceNodeItems?.[0].node }) &&
    isPastable(directive)
  ) {
    return {
      pasteAction: isCopy
        ? async (callback) => {
            await dispatch(pasteCopy(tree, targetNodeItem));
            callback();
          }
        : async (callback) => {
            await dispatch(pasteMove(tree, targetNodeItem));
            callback();
          },
    };
  }

  return {};
}
